// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

require("@rails/ujs").start()
require("@rails/activestorage").start()
require("channels")
const jQuery = require('jquery')
window.$ = jQuery
window.jQuery = jQuery

import "@fortawesome/fontawesome-free/js/all"

require('bootstrap/dist/js/bootstrap.bundle.min')
require('admin-lte')
require('admin-lte/plugins/chart.js/Chart.js')
require('admin-lte/plugins/daterangepicker/daterangepicker.js')
require('admin-lte/plugins/select2/js/select2.full.js')
require('moment/locale/ja')

// Highcharts
window.Highcharts = require('highcharts')
window.Highcharts.setOptions({
  global: {
    useUTC: false,
    timezone: 'Asia/Tokyo',
  },
  lang: {
    noData: "データがありません",
    months: ["1月", "2月", "3月", "4月", "5月", "6月", "7月", "8月", "9月", "10月", "11月", "12月"],
    shortMonths: ["1月", "2月", "3月", "4月", "5月", "6月", "7月", "8月", "9月", "10月", "11月", "12月"],
    weekdays: ["日", "月", "火", "水", "木", "金", "土"],
  },
});

// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)

import Swal from 'sweetalert2'

(function ($) {
  $.fn.torstr = Swal.mixin({
    toast: true,
    position: 'top-end',
    showConfirmButton: false,
    timer: 3000
  });

  $.fn.tableHighlighter = function(id) {
    let $el = $(id);
    $el.addClass("table-warning");
    $el.siblings().removeClass("table-warning");
  };

  $.fn.addNewRow = function(index, dom, parentSelector) {
    var regexp = new RegExp(index, 'g');

    var newIndex = (new Date()).getTime();
    var newRow = dom;
    newRow = newRow.replace(regexp, newIndex);

    $(parentSelector).append($(newRow));

    return false;
  };

  $.fn.removeRow = function(options) {
    this
      .off('click')
      .on('click', function() {
        var row = $(this).closest(options.fields);

        if ($(row).hasClass('persisted')) {
          $(row).find(options.destroyField).val(true);
          $(row).hide();
        } else {
          $(row).remove();
        }

        return false;
      });
  };

  $(function() {
    $(".select2").select2({
      theme: 'bootstrap4'
    });

    $('body').tooltip({selector: '[data-toggle="tooltip"]'});
  });
})(jQuery);
